<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>日程管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 
    <el-form :model="queryParams" layout="inline" :inline="true">
      <el-row :gutter="24">
        <el-col :md="12" :sm="12">
          <el-form-item label="时间" prop="monthValue">
            <el-date-picker
              v-model="monthValue"
              type="month"
              value-format="yyyy-MM"
              @change="changeMonth"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="6" :sm="24" style="width: 70%">
          <el-button type="primary" @click="queryParamsButton()"
            >搜索</el-button
          >
          <el-button type="primary" @click="primaryRest()">重置</el-button>
        </el-col>
      </el-row>

    <el-form-item class="show_type">
        <el-button
          type="warning"
          icon="el-icon-add"
          size="min"
          @click="testView('2')"
          >日历视图</el-button>
        <el-button
          type="warning"
          icon="el-icon-add"
          size="min"
          @click="testView('1')"
          >列表视图</el-button>
      </el-form-item> 
    </el-form> -->

    <!-- <el-row :gutter="20" style="margin: 9px 2px">
      <el-button type="warning" icon="el-icon-add" size="min">新增</el-button>
      <el-button type="warning" icon="el-icon-add" size="min">编辑</el-button>
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        v-if="this.caPlanType == 3"
        disabled
        >查询作息时间</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        v-if="this.caPlanType == 4"
        disabled
        >填写进度</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        v-if="this.caPlanType == 1 || this.caPlanType == 3"
        disabled
        >查看明日计划</el-button
      >
      <el-button type="warning" icon="el-icon-add" size="min" disabled
        >发送计划</el-button
      >
    </el-row> -->
    <!-- <div v-if="this.changeViewType == 1">
      <el-table
        :data="dictList"
        ref="multipleTable"
        @row-dblclick="dataBackFillGoods"
      >
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="版本" align="center" prop="version" />
        <el-table-column
          label="目标时间"
          align="center"
          prop="targetTimeFrom"
          v-if="this.planType == 1"
        />
        <el-table-column
          label="模式"
          align="center"
          prop="model"
          v-if="this.planType == 3"
        />
        <el-table-column
          label="每周几执行"
          align="center"
          prop="day"
          v-if="this.planType == 2"
        />
        <el-table-column
          label="等级"
          align="center"
          prop="grade"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="标题"
          align="center"
          prop="title"
          v-if="this.planType != 3"
        />

        <el-table-column
          label="时长"
          align="center"
          prop="duration"
          v-if="this.planType == 1 || this.planType == 2"
        />
        <el-table-column
          label="预算"
          align="center"
          prop="budget"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="进度"
          align="center"
          prop="progressRate"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="标签"
          align="center"
          prop="label"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="开始时间"
          align="center"
          prop="planStartTime"
          v-if="this.planType == 4"
        />
        <el-table-column
          label="结束时间"
          align="center"
          prop="planEndTime"
          v-if="this.planType == 4"
        />
        <el-table-column label="详情" align="center" v-if="this.planType == 4">
          <template slot-scope="scope">
            <el-button
              style="color: #108ee9; cursor: pointer"
              @click="showTripButton(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          label="目标时间"
          align="center"
          prop="targetTime"
          v-if="this.planType == 4"
        />

        <el-table-column
          label="截止时间"
          align="center"
          prop="deadlineTime"
          v-if="this.planType == 4"
        />

        <el-table-column label="状态" align="center" prop="status" />
        <el-table-column label="备注" align="center" prop="remark" />
      </el-table>
      <pagination
        :total="queryParams.total"
        :page-size.sync="queryParams.pageSize"
        :current-page.sync="queryParams.currentPage"
      />
    </div> -->
    <div>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-statistic
            group-separator=","
            :precision="2"
            :value="this.titleMoney.money"
            title="总支出(个人+可报销)"
          ></el-statistic>
        </el-col>
        <el-col :span="5">
          <el-statistic
            group-separator=","
            :precision="2"
            :value="this.titleMoney.totalReturnAmounts"
            title="可报销总金额"
          ></el-statistic>
        </el-col>
        <el-col :span="5">
          <el-statistic
            group-separator=","
            :precision="2"
            :value="this.titleMoney.totalActualAmount"
            title="个人花费金额"
          ></el-statistic>
        </el-col>
        <el-col :span="5">
          <el-statistic
            group-separator=","
            :precision="2"
            :value="this.titleMoney.incomeMoney"
            title="总收益(除去个人)"
          ></el-statistic>
        </el-col>
      </el-row>
    </div>
    <!-- 日历视图表 -->
    <div v-if="this.changeViewType == 2 || this.changeViewType == 4">
      <span style="margin: 5px 10px">{{ noweDataFrom }}</span>
      <el-calendar
        style="margin: 5px 10px"
        v-model="nowdate"
        @dblclick.native="handleDblClick"
      >
        <template slot="dateCell" slot-scope="{ date, data }">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.slice(-2) }} {{ data.isSelected ? "✔️" : "" }}
          </p>
          <div v-for="(i, index) in dayList" :key="index">
            <div v-if="data.day == i.recordDate">
              <span
                style="color: #67c23a"
                v-if="i.totalReturnAmounts - i.money > 0"
                >+{{ (i.totalReturnAmounts - i.money) | capitalize }}</span
              >
              <span style="color: #f56c6c" v-else>{{
                (i.totalReturnAmounts - i.money) | capitalize
              }}</span>
              <span
                v-if="i.totalReturnAmounts != null"
                @click="selectMoneyButton(i.recordDate)"
              >
                (实际消费:{{ i.money | capitalize }}元)
              </span>
              <span @click="selectMoneyButton(i.recordDate)" v-else
                >实际消费:{{ i.money | capitalize }}元</span
              >
            </div>
          </div>
        </template>
      </el-calendar>
    </div>
    <el-dialog
      title="新增数据"
      :visible.sync="outerVisible"
      width="90%"
      :before-close="handleClose"
    >
      <el-steps :active="active" simple finish-status="success">
        <el-step title="步骤 1" icon="el-icon-edit"></el-step>
        <el-step title="步骤 2" icon="el-icon-s-tools"></el-step>
      </el-steps>
      <div v-if="this.active == 1">
        <el-form :model="addEditForm" ref="addEditForm">
          <el-row :gutter="24">
            <el-form-item label="记录类型" prop="bindType">
              <span style="color: red">(切换会清空下一步的数据) </span>
              <el-radio-group
                v-model="addEditForm.bindType"
                @change="bindTypeButton"
                prop="bindType"
              >
                <el-radio :label="1">消费记录</el-radio>
                <el-radio :label="2">目标规划</el-radio>
              </el-radio-group>
              &nbsp;
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <!-- 添加消费记录 -->
      <div v-if="this.active == 2 && this.addEditForm.bindType == 1">
        <el-row :gutter="24" class="mb8">
          <el-col :span="8">
            <span>类型:</span>
            <el-select
              v-model="bindTypes"
              placeholder="请选择"
              @change="getBindTypeList"
            >
              <el-option
                v-for="item in consumptionTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- </el-col>
          <el-col :span="4"> -->
            &nbsp;
            <el-button
              icon="el-icon-plus"
              size="mini"
              round
              @click="addConsumeButton"
              style="margin: 10px 0px 0px 0px"
            ></el-button>
          </el-col>

          <el-col :span="12">
            <el-input
              placeholder="快速填充:(列如早餐:10,中餐20) 回车确认"
              v-model="fastData"
              clearable
              @keyup.enter.native="handleSubmit"
            />
          </el-col>
        </el-row>

        <el-table
          :data="consumeList"
          border
          stripe
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center' }"
          show-summary
        >
          <el-table-column label="序号" type="index" />
          <el-table-column label="操作" prop="generalIncome" width="70">
            <template slot-scope="scope">
              <!-- <el-button
                size="mini"
                icon="el-icon-edit-outline"
                round
                @click.native.prevent="
                  deleteRow(scope.$index, consumeList, scope.row)
                "
              /> -->
              <el-button
                size="mini"
                icon="el-icon-minus"
                round
                @click.native.prevent="
                  deleteRow(scope.$index, consumeList, scope.row)
                "
              />
            </template>
          </el-table-column>
          <el-table-column label="类别" prop="twoCategory">
            <template slot-scope="scope">
              <el-cascader
                v-model="scope.row.twoCategory"
                :options="options"
                @change="handleChangeCate(scope.row)"
              ></el-cascader>
            </template>
          </el-table-column>

          <!-- <el-table-column label="类别" prop="category">
            <template slot-scope="scope">
              <el-select v-model="scope.row.category" placeholder="请选择">
                <el-option
                  v-for="item in categoryTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="子类型" prop="twoCategory" width="140">
            <template slot-scope="scope">
              <el-select v-model="scope.row.twoCategory" placeholder="请选择">
                <el-option
                  v-for="item in categoryTwoTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column> -->
          <el-table-column label="名称" prop="name">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="总金额" prop="totalAmount">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                style="width: 95%"
                size="medium"
                v-model="scope.row.totalAmount"
              />
            </template>
          </el-table-column>
          <el-table-column label="实际消费" prop="actualiAmount">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                style="width: 95%"
                size="medium"
                v-model="scope.row.actualiAmount"
              />
            </template>
          </el-table-column>
          <el-table-column label="是否报销" prop="isReimburse" width="120px">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.isReimburse"
                border
                :true-label="1"
                :false-label="2"
                >是</el-checkbox
              >
            </template>
          </el-table-column>
          <el-table-column label="可报销金额" prop="returnAmount">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                style="width: 95%"
                size="medium"
                v-model="scope.row.returnAmount"
                :disabled="scope.row.isReimburse != 1"
              />
            </template>
          </el-table-column>

          <el-table-column label="支付方式" prop="paymentMethod">
            <template slot-scope="scope">
              <el-select v-model="scope.row.paymentMethod" placeholder="请选择">
                <el-option
                  v-for="item in paymentMethodTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="地点" prop="location">
            <template slot-scope="scope">
              <el-input v-model="scope.row.location"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="comments">
            <template slot-scope="scope">
              <el-input v-model="scope.row.comments"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="绑定事件" prop="generalIncome">
            <span>暂不开放</span>
          </el-table-column>
        </el-table>
      </div>

      <div style="margin: 0 0 0 85%">
        <el-button
          style="margin-top: 12px"
          @click="nextTo"
          v-if="this.active == 2"
          >上一步</el-button
        >
        <el-button
          style="margin-top: 12px"
          @click="next"
          v-if="this.active == 1"
          >下一步</el-button
        >
        <el-button style="margin-top: 12px" @click="addConsumpButton" v-else
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="新增数据"
      :visible.sync="selectMoneyVisible"
      width="90%"
      :before-close="handleClose"
    >
      <!-- 添加消费记录 -->
      <div>
        <el-row :gutter="24" class="mb8">
          <el-col :span="8">
            <span>类型:</span>
            <el-select
              v-model="bindTypes"
              placeholder="请选择"
              @change="getBindTypeList"
            >
              <el-option
                v-for="item in consumptionTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- </el-col>
          <el-col :span="4"> -->
            &nbsp;
            <el-button
              icon="el-icon-plus"
              size="mini"
              round
              @click="addConsumeButton"
              style="margin: 10px 0px 0px 0px"
            ></el-button>
          </el-col>
          <el-col :span="12">
            <el-input
              placeholder="快速填充:(列如早餐:10,中餐20) 回车确认"
              v-model="fastData"
              clearable
              @keyup.enter.native="handleSubmit"
            />
          </el-col>
        </el-row>

        <el-table
          :data="consumeList"
          border
          stripe
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center' }"
          show-summary
        >
          <el-table-column label="序号" type="index" />
          <el-table-column label="操作" prop="generalIncome" width="70">
            <template slot-scope="scope">
              <!-- <el-button
                size="mini"
                icon="el-icon-edit-outline"
                round
                @click.native.prevent="
                  deleteRow(scope.$index, consumeList, scope.row)
                "
              /> -->
              <el-button
                size="mini"
                icon="el-icon-minus"
                round
                @click.native.prevent="
                  deleteRow(scope.$index, consumeList, scope.row)
                "
              />
            </template>
          </el-table-column>
          <el-table-column label="类别" prop="twoCategory">
            <template slot-scope="scope">
              <el-cascader
                v-model="scope.row.twoCategory"
                :options="options"
                @change="handleChangeCate(scope.row)"
              ></el-cascader>
            </template>
          </el-table-column>

          <!-- 
          <el-table-column label="类型" prop="type" width="140">
            <template slot-scope="scope">
              <el-select v-model="scope.row.type" placeholder="请选择">
                <el-option
                  v-for="item in consumptionTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="类别" prop="category">
            <template slot-scope="scope">
              <el-select v-model="scope.row.category" placeholder="请选择">
                <el-option
                  v-for="item in categoryTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column> -->
          <el-table-column label="名称" prop="name">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="总金额" prop="totalAmount">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                style="width: 95%"
                size="medium"
                v-model="scope.row.totalAmount"
              />
            </template>
          </el-table-column>
          <el-table-column label="实际消费" prop="actualiAmount">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                style="width: 95%"
                size="medium"
                v-model="scope.row.actualiAmount"
              />
            </template>
          </el-table-column>
          <el-table-column label="是否报销" prop="isReimburse" width="120px">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.isReimburse"
                border
                :true-label="1"
                :false-label="2"
                >是</el-checkbox
              >
            </template>
          </el-table-column>
          <el-table-column label="可报销金额" prop="returnAmount">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                style="width: 95%"
                size="medium"
                v-model="scope.row.returnAmount"
                :disabled="scope.row.isReimburse != 1"
              />
            </template>
          </el-table-column>
          <el-table-column label="支付方式" prop="paymentMethod">
            <template slot-scope="scope">
              <el-select v-model="scope.row.paymentMethod" placeholder="请选择">
                <el-option
                  v-for="item in paymentMethodTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="地点" prop="location">
            <template slot-scope="scope">
              <el-input v-model="scope.row.location"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="comments">
            <template slot-scope="scope">
              <el-input v-model="scope.row.comments"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="绑定事件" prop="generalIncome">
            <span>暂不开放</span>
          </el-table-column>
        </el-table>
      </div>

      <div style="margin: 0 0 0 85%">
        <el-button style="margin-top: 12px" @click="addConsumpButton"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import {
  dataListConsumeApi,
  addBathConsumeApi,
  selectInfoListByIdApi,
} from "@/api/life/life/consume";
import format from "@/plugins/format.js";
export default {
  data() {
    return {
      value2: 1314,
      options: [
        {
          value: "foot",
          label: "伙食",
          children: [
            {
              value: "breakfast",
              label: "早餐",
            },
            {
              value: "lunch",
              label: "午餐",
            },
            {
              value: "dinner",
              label: "晚餐",
            },
            {
              value: "supper",
              label: "夜宵",
            },
          ],
        },
        {
          value: "clothing",
          label: "衣服",
          children: [
            {
              value: "shirt",
              label: "衬衫",
            },
          ],
        },
        {
          value: "task",
          label: "打车",
          children: [
            {
              value: "taxi",
              label: "的士",
            },
            {
              value: "metro",
              label: "地铁",
            },
          ],
        },
        {
          value: "others",
          label: "其他",
          children: [
            {
              value: "other",
              label: "其他",
            },
          ],
        },
      ],
      bindTypes: "1",
      selectMoneyVisible: false,
      fastData: "",
      consumptionTypeList: [
        {
          value: "1",
          label: "日常",
        },
        {
          value: "2",
          label: "出差",
        },
        {
          value: "3",
          label: "旅游",
        },
      ],
      categoryTypeList: [
        {
          value: "1",
          label: "伙食",
        },
        {
          value: "2",
          label: "衣服",
        },
        {
          value: "3",
          label: "打车",
        },
        {
          value: "4",
          label: "购物",
        },
        {
          value: "99",
          label: "其他",
        },
      ],
      categoryTwoTypeList: [{}],
      paymentMethodTypeList: [
        {
          value: "1",
          label: "微信",
        },
        {
          value: "2",
          label: "支付宝",
        },
        {
          value: "3",
          label: "现金",
        },
        {
          value: "99",
          label: "其他",
        },
      ],
      consumeList: [],
      addEditForm: {
        bindType: 1,
      },
      nowdate: new Date(),
      radio: null,
      dictList: [],
      caPlanType: 1,
      planType: "1",
      dataColumn: this.dayjs(this.dayjs()).format("YYYY-MM-DD"),
      queryParams: {
        recodeMonth: new Date(),
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      changeViewType: 2,
      planTypeList: [],
      uniqueId: null,
      dayList: [],
      noweDataFrom: format(new Date(), "YYYY-MM"),
      pickData: "",
      outerVisible: false,
      innerVisible: false,
      active: 1,
      recordDate: "",
      monthss: "",
      titleMoney: {
        totalReturnAmounts: 0,
        totalActualAmount: 0,
        money: 0,
        incomeMoney: 0,
      },
    };
  },
  filters: {
    capitalize(str) {
      //把首字母转为大写的过滤器
      return str.toFixed(2);
    },
  },
  watch: {
    nowdate() {
      var year = this.nowdate.getFullYear();
      var month = this.nowdate.getMonth() + 1;
      var date = this.nowdate.getDate();
      if (date >= 1 && date <= 9) {
        //日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {
        //月如果小于10就补个0
        month = "0" + month;
      }
      //这个时间是变了的
      this.noweDataFrom = format(this.nowdate, "YYYY-MM");
      if (this.monthss == null) {
        this.queryParamsButton();
        this.monthss = this.noweDataFrom;
      } else {
        if (this.monthss != this.noweDataFrom) {
          this.monthss = this.noweDataFrom;
          this.queryParamsButton();
        }
      }
      this.pickData = format(this.nowdate, "YYYY-MM-DD");
    },
  },
  computed: {
    monthValue: {
      get: function () {
        return this.nowdate;
      },
      set: function (newValue) {
        this.nowdate = newValue;
      },
    },
  },
  created() {
    this.queryParamsButton();
  },
  methods: {
    queryParamsButton() {
      this.queryParams.group = null;
      this.queryParams.monthData = this.noweDataFrom;
      dataListConsumeApi(this.queryParams).then((res) => {
        this.dayList = res.result;
      });
      //在这里再加入todo
      this.queryParams.group = "month";
      dataListConsumeApi(this.queryParams).then((res) => {
        if (res.result[0] != null) {
          this.titleMoney = res.result[0];
        }else{
          this.titleMoney.totalReturnAmounts=0;
          this.titleMoney.totalActualAmount= 0
          this.titleMoney.money= 0
          this.titleMoney.incomeMoney=0
        }
      });
    },
    //月下拉选框
    changeMonth() {
      this.nowdate = new Date(this.nowdate);
      this.queryParamsButton();
    },
    handleDblClick(date, datePicker) {
      const match = this.dayList.find(
        (item) => item.recordDate === this.pickData
      );
      if (match) {
        console.log("找到匹配的日期");
      } else {
        console.log("date", date);
        this.outerVisible = true;
      }
    },
    getPlanType(value) {
      if (value != 1) {
        this.changeViewType = 1;
      }
      this.caPlanType = value;
      this.uniqueId = null;
      this.radio = null;
    },
    testView(type) {
      this.changeViewType = type;
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
    },
    getCurrentRow(row) {
      this.uniqueId = row.uniqueId;
    },
    dataBackFillGoods(row, column) {
      console.log(row);
      console.log(column);
    },
    showTripButton(row) {
      this.$refs.bondStructurePlanInfoFrom.show(row, 1);
    },
    next() {
      if (
        this.addEditForm.bindType == 0 ||
        this.addEditForm.bindType == null ||
        this.addEditForm.bindType == undefined
      )
        return this.$message.error("请选择类型");
      if (this.active++ >= 2) this.active = 1;
    },
    nextTo() {
      if (this.active-- >= 1) this.active = 1;
    },
    addConsumeButton() {
      var data = {
        type: this.bindTypes,
        isReimburse: 1,
      };
      this.consumeList.push(data);
    },
    deleteRow(index, rows, row) {
      rows.splice(index, 1);
    },
    handleSubmit() {
      if (this.fastData != "" && this.fastData != null) {
        var array = [];
        if (!this.fastData.includes(":")) {
          array.push(this.fastData);
          console.log(array);
        } else {
          array = this.fastData.split(",");
        }
        for (var i = 0; i < array.length; i++) {
          const item = array[i];
          if (item.includes(":")) {
            let parts = item.split(":");
            // 第一部分
            let firstPart = parts[0];
            // 最后部分
            let lastPart = parts[parts.length - 1];
            var dataOne = {
              type: this.bindTypes,
              category: "1",
              name: firstPart,
              totalAmount: lastPart,
              actualiAmount: lastPart,
              paymentMethod: "99",
              isReimburse: 1,
              twoCategory: "other",
            };
            this.consumeList.push(dataOne);
          }
        }
      }
      this.fastData = null;
    },
    addConsumpButton() {
      this.addEditForm.consumeList = this.consumeList;
      this.addEditForm.bindType = 1;
      this.addEditForm.recordDate = this.pickData;
      // //这里调用保存接口
      addBathConsumeApi(this.addEditForm).then((res) => {
        this.outerVisible = false;
        this.consumeList = [];
        this.addEditForm = {};
        this.active = 1;
        this.selectMoneyVisible = false;
        this.queryParamsButton();
      });
    },
    bindTypeButton(row) {
      if (row != 1) {
        this.consumeList = [];
      }
    },
    selectMoneyButton(row) {
      this.selectMoneyVisible = true;
      var quers = {
        recordDate: row,
      };
      selectInfoListByIdApi(quers).then((res) => {
        this.consumeList = res.result.consumeList;
      });
    },
    handleClose() {
      this.outerVisible = false;
      this.consumeList = [];
      this.addEditForm = {};
      this.active = 1;
      this.fastData = null;
      this.selectMoneyVisible = false;
      this.queryParamsButton();
    },
    getBindTypeList() {
      this.consumeList = [];
      this.fastData = null;
    },
    handleChange(value) {
      console.log(value);
    },
    handleChangeCate(row){
      row.twoCategory=row.twoCategory[1]
    }
  },
};
</script>
  <style lang="less" scoped>
/deep/.el-calendar__title {
  display: none !important;
}
/deep/.el-calendar__button-group {
  margin: auto;
}
/deep/.show_type {
  float: right;
}
// /deep/.reminder_class {
//   width: 120px;
// }
.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}

.custom-statistic {
  /* 标题字体大小 */
  font-size: 18px;
  /* 标题字体颜色 */
  color: blue;
}
</style>