import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunConsume'


const listconsumeApi =(params)=>getAction(baseURL+"/list",params);


const dataListConsumeApi =(params)=>getAction(baseURL+"/dataList",params);



const listDatsConsumeApi =(params)=>getAction(baseURL+"/listDats",params);
const addBathConsumeApi=(params)=>postAction(baseURL+"/addBath",params);

const addconsumeApi=(params)=>postAction(baseURL+"/add",params);

const editconsumeApi=(params)=>putAction(baseURL+"/edit",params);

const deleteconsumeByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectconsumeByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const selectInfoListByIdApi=(params)=>postAction(baseURL+"/selectInfoListById",params);

export{
    selectconsumeByIdApi,
    deleteconsumeByIdApi,
    editconsumeApi,
    addconsumeApi,
    listconsumeApi,
    dataListConsumeApi,
    addBathConsumeApi,
    selectInfoListByIdApi,
    listDatsConsumeApi
}
